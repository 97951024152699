.top-menu {
  .logo {
    height: 100px !important;
  }

  &__user {
    height: 50px;
    float: right;
    
    button {
      margin-left: 10px;
    }

    h3{
        position: 0;
        margin-left: 10px;
        display: inline;
        color: white;
    }
  }
}
