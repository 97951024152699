.auth {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: 100vh;
  height: 100vh;
  display: flex;
  -moz-box-pack: end;
  justify-content: flex-end;
  -moz-box-align: center;
  align-items: center;
  position: relative;
  background-size: cover;

  &__dark {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100vh;
    min-width: 100%;
    background-color: #000;
    opacity: 0.2;
    z-index: 0;
  }

  &__box {
      position: absolute;
      right: 0;
    width: 500px;
    height: 100%;
    min-height: 100%;
    overflow-y: auto;
    z-index: 1;
    display: flex;
    flex-direction: column;
    padding: 56px 40px;
    position: relative;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;

    &-logo {
      align-content: center;  
      text-align: center;
      img {
        width: 300px;
      }
    }
  }
}
