.device-catalog {
  display: flex !important;
  justify-content: center !important;
  margin-top: 25px;

  .ant-ribbon-wrapper {
    margin-left: 10px;
    margin-right: 10px;
  }

  

  .ant-card {
    width: 300px;
    min-width: 300px;
    box-shadow: 5px 5px 5px 5px #C6BAB8;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .ant-card-cover {
    background-color: #205c95;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    div {

      height: 150px;

      span {
        display: block;
        padding-top: 15px;
        margin-left: auto;
        margin-right: auto;
      }
      
      h3 {
        color: #ffffff66;
        top: 65%;
        position: absolute;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
      }

      h2 {
        color: #ffffff66;
        top: 85%;
        position: absolute;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
