.extended-list {
  
  box-shadow: 5px 5px 5px 5px #C6BAB8;
  border-radius: 27px;

  .ant-list-header{
    border-radius: 25px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: #205c95;
    color: white;
    font-weight: bold;
  
  }
  
  .ant-list-footer {
    border-radius: 25px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-color: #205c95;
    color: white;
    font-weight: bold;
    
  }
  
  .badge-count .ant-badge-count{
    background-color: #fff;
    color: #000;
    box-shadow: 0 0 0 1px #d9d9d9 inset;
  }
}
