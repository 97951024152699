.login-form {
  color: white;
  padding-top: 25px;
  padding-left: 10px;
  padding-right: 10px;

  .login-form-forgot {
    float: right;
  }

  .login-form-register {
    text-align: center;
}
  
  .login-form-button {
    width: 100%;
    margin-bottom: 20px;
  }
}
